import { Box, Container, Flex, Grid, Heading, Link, Text } from "@chakra-ui/react";
import SnowdayIcons, { iconTypes } from "@/icons/SnowdayIcons";
import BgBottom from "@/ui/waves/BgBottom";
import BgTop from "@/ui/waves/BgTop";
interface CTAProps {
  icon: keyof typeof iconTypes;
  title: string;
}
const CTAItems: CTAProps[] = [{
  icon: "Snow",
  title: "Suggest a Learning Opportunity"
}, {
  icon: "Flag",
  title: "Flag incorrect information"
}];
const CTAItem = ({
  icon,
  title
}: CTAProps) => <Link display="flex" borderRadius={4} flexDirection="column" width={{
  base: "160px",
  lg: "200px"
}} height={{
  base: "160px",
  lg: "200px"
}} py={{
  base: 5,
  md: 3,
  lg: 6
}} px={{
  base: 2,
  lg: 3
}} boxShadow="0px 1px 2px rgba(52, 67, 114, 0.08), 0px 2px 4px rgba(52, 67, 114, 0.2)" textAlign="center" alignItems="center" href={`mailto:theo@snow.day?subject=${title}`} bg="white" data-sentry-element="Link" data-sentry-component="CTAItem" data-sentry-source-file="SnowdayHelp.tsx">
    <SnowdayIcons name={icon} width={{
    base: "60px",
    lg: "80px"
  }} height={{
    base: "60px",
    lg: "80px"
  }} mb={{
    base: 3,
    lg: 6
  }} data-sentry-element="SnowdayIcons" data-sentry-source-file="SnowdayHelp.tsx" />
    <Flex flexDirection="column" flexGrow={1} justifyContent="center" data-sentry-element="Flex" data-sentry-source-file="SnowdayHelp.tsx">
      <Text fontWeight={700} color="snow.blue-dark" fontSize={{
      base: 14,
      lg: 16
    }} data-sentry-element="Text" data-sentry-source-file="SnowdayHelp.tsx">
        {title}
      </Text>
    </Flex>
  </Link>;
export default function SnowdayHelp() {
  return <>
      <BgTop data-sentry-element="BgTop" data-sentry-source-file="SnowdayHelp.tsx" />
      <Box as="section" pb={{
      md: 5
    }} px={{
      base: 3,
      md: 0
    }} bg="snow.blue-light" pt={{
      base: 10,
      md: 5
    }} data-sentry-element="Box" data-sentry-source-file="SnowdayHelp.tsx">
        <Container px={0} maxWidth={{
        lg: "890px"
      }} alignItems="center" display="flex" flexDirection="column" data-sentry-element="Container" data-sentry-source-file="SnowdayHelp.tsx">
          <Heading mx="auto" variant="h2" color="snow.blue-medium" textAlign="center" maxW={{
          md: "332px"
        }} mb={4} data-sentry-element="Heading" data-sentry-source-file="SnowdayHelp.tsx">
            Snowday is built
            <br />
            by teens and educators,
            <br />
            for teens and educators.
          </Heading>
          <Heading mx="auto" variant="h3" color="snow.blue-medium" textAlign="center" fontWeight={400} mb={12} data-sentry-element="Heading" data-sentry-source-file="SnowdayHelp.tsx">
            Join the project to help build this shared and free resource.
          </Heading>
          <Grid gap={{
          base: 3,
          md: 5
        }} templateColumns={{
          base: "repeat(2, 160px)",
          // keeps two items per row on smaller screens
          md: "repeat(2, 1fr)"
        }} justifyContent="center" // centers the grid items
        data-sentry-element="Grid" data-sentry-source-file="SnowdayHelp.tsx">
            {CTAItems.map(({
            title,
            icon
          }, i) => <CTAItem key={i} title={title} icon={icon} />)}
          </Grid>
        </Container>
      </Box>
      <BgBottom data-sentry-element="BgBottom" data-sentry-source-file="SnowdayHelp.tsx" />
    </>;
}