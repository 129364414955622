import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import SearchScreen from "../screen";
import { TopSearchDataType } from "../types";
import { Header } from "@/ui/common/Header";
import Footer from "@/ui/footer/Footer";
type SearchPageModalProps = {
  onClose: () => void;
  isOpen: boolean;
  topSearchData: TopSearchDataType[];
};
export const SearchPageModal = ({
  onClose,
  isOpen,
  topSearchData
}: SearchPageModalProps) => {
  return <Modal onClose={onClose} isOpen={isOpen} size="full" scrollBehavior="outside" isCentered={false} data-sentry-element="Modal" data-sentry-component="SearchPageModal" data-sentry-source-file="SearchPageModal.tsx">
      <ModalOverlay data-sentry-element="ModalOverlay" data-sentry-source-file="SearchPageModal.tsx" />
      <ModalContent m={0} top="0" data-sentry-element="ModalContent" data-sentry-source-file="SearchPageModal.tsx">
        <Header title={"learning-opportunities"} onClick={onClose} data-sentry-element="Header" data-sentry-source-file="SearchPageModal.tsx" />
        <SearchScreen topSearches={topSearchData} onClick={onClose} data-sentry-element="SearchScreen" data-sentry-source-file="SearchPageModal.tsx" />
        <Footer data-sentry-element="Footer" data-sentry-source-file="SearchPageModal.tsx" />
      </ModalContent>
    </Modal>;
};