import { GetServerSideProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import HomeScreen from "@/screens/home/screen";
import { useTopSearchData } from "@/search/hooks/useTopSearchData";
export const getServerSideProps: GetServerSideProps = async ({
  locale = "en"
}) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["titles"]))
    }
  };
};
const HomePage = () => {
  const topSearches = useTopSearchData();
  return <HomeScreen topSearches={topSearches} data-sentry-element="HomeScreen" data-sentry-component="HomePage" data-sentry-source-file="index.tsx" />;
};
export default HomePage;