import { Box, Container, Flex, Heading, ListItem, UnorderedList } from "@chakra-ui/react";
import SnowdayIcons from "@/icons/SnowdayIcons";
import { breakpoints, useWindowSize } from "@/ui/hooks";
import BgBttom from "@/ui/waves/BgBottom";
import BgTop from "@/ui/waves/BgTop";
export default function SnowdayIntro() {
  const {
    width
  } = useWindowSize();
  const isSmallScreen = width && width < breakpoints.lg;
  return <>
      <BgTop width="100%" data-sentry-element="BgTop" data-sentry-source-file="SnowdayIntro.tsx" />
      <Box as="section" pt={{
      base: 5,
      lg: 10
    }} pb={{
      base: 10,
      lg: 20
    }} bg="snow.blue-light" px={{
      base: 6,
      md: 10
    }} data-sentry-element="Box" data-sentry-source-file="SnowdayIntro.tsx">
        <Container px={0} maxWidth={{
        lg: "890px"
      }} data-sentry-element="Container" data-sentry-source-file="SnowdayIntro.tsx">
          <Heading mx="auto" variant="h2" color="snow.blue-medium" textAlign="center" maxW={{
          md: "330px",
          lg: "470px"
        }} mb={12} fontWeight="bold" data-sentry-element="Heading" data-sentry-source-file="SnowdayIntro.tsx">
            The most comprehensive database of learning opportunities for high
            schoolers.
          </Heading>
          <Flex flexDirection={isSmallScreen ? "column" : "row"} gap={10} data-sentry-element="Flex" data-sentry-source-file="SnowdayIntro.tsx">
            <Flex flexDirection="row" gap={4} alignItems="center" // Adjust alignment as needed
          justifyContent="center" // Center the content within the Flex
          width="100%" // Ensure Flex takes the full width
          data-sentry-element="Flex" data-sentry-source-file="SnowdayIntro.tsx">
              <SnowdayIcons name="Research" boxSize={24} data-sentry-element="SnowdayIcons" data-sentry-source-file="SnowdayIntro.tsx" />
              <Box data-sentry-element="Box" data-sentry-source-file="SnowdayIntro.tsx">
                <Heading variant="h3" mb={2} fontWeight="bold" data-sentry-element="Heading" data-sentry-source-file="SnowdayIntro.tsx">
                  Search 2,100+ Opportunities
                </Heading>
                <UnorderedList data-sentry-element="UnorderedList" data-sentry-source-file="SnowdayIntro.tsx">
                  <ListItem data-sentry-element="ListItem" data-sentry-source-file="SnowdayIntro.tsx">New: 200+ competitions</ListItem>
                  <ListItem data-sentry-element="ListItem" data-sentry-source-file="SnowdayIntro.tsx">700+ school year programs</ListItem>
                  <ListItem data-sentry-element="ListItem" data-sentry-source-file="SnowdayIntro.tsx">1,200+ top summer programs</ListItem>
                </UnorderedList>
              </Box>
            </Flex>
            <Flex flexDirection="row" gap={4} alignItems="center" // Adjust alignment as needed
          justifyContent="center" // Center the content within the Flex
          width="100%" // Ensure Flex takes the full width
          data-sentry-element="Flex" data-sentry-source-file="SnowdayIntro.tsx">
              <SnowdayIcons name="FlowerPot" boxSize={24} data-sentry-element="SnowdayIcons" data-sentry-source-file="SnowdayIntro.tsx" />
              <Box data-sentry-element="Box" data-sentry-source-file="SnowdayIntro.tsx">
                <Heading variant="h3" mb={2} fontWeight="bold" data-sentry-element="Heading" data-sentry-source-file="SnowdayIntro.tsx">
                  Save Time and Don't Miss Out
                </Heading>
                <UnorderedList data-sentry-element="UnorderedList" data-sentry-source-file="SnowdayIntro.tsx">
                  <ListItem data-sentry-element="ListItem" data-sentry-source-file="SnowdayIntro.tsx">Search powered by AI</ListItem>
                  <ListItem data-sentry-element="ListItem" data-sentry-source-file="SnowdayIntro.tsx">Filter by location, date, cost, and more</ListItem>
                  <ListItem data-sentry-element="ListItem" data-sentry-source-file="SnowdayIntro.tsx">Watch program videos</ListItem>
                </UnorderedList>
              </Box>
            </Flex>
          </Flex>
        </Container>
      </Box>
      <BgBttom width="100%" data-sentry-element="BgBttom" data-sentry-source-file="SnowdayIntro.tsx" />
    </>;
}